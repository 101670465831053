import React, { useRef } from "react";
import { Image as ImageImpl } from "@react-three/drei";
import * as THREE from "three";
import { useFrame } from "@react-three/fiber";

function PngLoader(props) {
  const groupRef = useRef();

  useFrame(({ clock }) => {
    if (props.rotate) {
      if (groupRef.current) {
        const elapsedTime = clock.elapsedTime;
        const radius = 1; // Adjust the radius of the circular path
        const rotationSpeed = 0.5; // Adjust the rotation speed

        // Calculate new position in circular motion
        const x = Math.cos(elapsedTime * rotationSpeed) * radius;
        const z = Math.sin(elapsedTime * rotationSpeed) * radius;

        groupRef.current.position.set(x, 0, z);
      }
    }
  });

  return (
    <group ref={groupRef} {...props}>
      <ImageImpl
        position={props.position && props.position}
        scale={props.scale}
        transparent={props.transparent}
        renderOrder={props.renderOrder}
        url={props.path}
        side={THREE.DoubleSide}
        zoom={props.zoom && props.zoom}
        rotation={props.rotation ? props.rotation : new THREE.Euler(0, 0, 0)}
        opacity={props.opacity && props.opacity}
        toneMapped={false}
      />
    </group>
  );
}

export default PngLoader;
