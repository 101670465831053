import { useContext, useEffect, useRef, useState } from "react";
import MSDFShader from "../utils/textGradient";
import * as THREE from "three";
import { fragment } from "../shaders/fragment";
import { vertex } from "../shaders/vertex";
import { FontLoader } from "three/addons/loaders/FontLoader.js";
import { useFrame, useThree } from "@react-three/fiber";
import TextGeometryComponent from "../utils/textGeometry";
import { useScroll } from "./ScrollControls";
import { TextGeometry } from "three/addons/geometries/TextGeometry.js";
import { LoadingContext } from "../context/loading.context";
import { Section3Context } from "../context/section3.context";

const MovingTextComponent = () => {
  const [generatedTextMesh, setGeneratedTextMesh] = useState(null);
  const [generatedPoints, setGeneratedPoints] = useState(null);
  const [fontTexture, setFontTexture] = useState(null);
  const [font, setFont] = useState(null);
  const [neulisAltFont, setNeulisAltFont] = useState(null);
  const [neulisAltFontMed, setNeulisAltFontMed] = useState(null);
  const [helvetikerFont, setHelvetikerFont] = useState(null);
  const [textOpacity, setTextOpacity] = useState(0);
  const [particlesCount, setParticlesCount] = useState();
  const { activeMode, showPlayButtons } = useContext(Section3Context);
  const { setLoading } = useContext(LoadingContext);
  const subTextMaterial = useRef(null);
  const bodyTextMaterial = useRef(null);
  const textOpacityInterval = useRef(null);
  const isTextRemoveInProgress = useRef(null);
  const isTextAddInProgress = useRef(null);
  const activeText = useRef(null);
  const { width } = useThree((state) => state.viewport);
  const { scene } = useThree();
  const { scroll } = useScroll();

  useEffect(() => {
    if (textOpacity == 0 || textOpacity == 1) {
      isTextRemoveInProgress.current = false;
    }
  }, [textOpacity]);
  useEffect(() => {
    if (
      !subTextMaterial.current &&
      !bodyTextMaterial.current &&
      !generatedTextMesh &&
      isTextRemoveInProgress.current
    ) {
      isTextRemoveInProgress.current = false;
    }
  }, [subTextMaterial.current, bodyTextMaterial.current, generatedTextMesh]);

  // section 2 details
  const section2TextDetails = useRef({
    isAdded: false,
    text: "WE TAKE IT TO\nTHE FIELD",
    align: "center",
    pointSize: 1.3,
    subText:
      "Since 2018 we have explored Cannabis \nSativa and its versatility",
    bodyText:
      "We partner with the best producers to assure top \nquality selection of new phenotypes. This is why \ntoday we are able to market unique products.",
  });

  // SECTION 2 OUTDOOR
  const section2OutdoorTextDetails = useRef({
    isAdded: false,
    text: "OUTDOOR",
    align: "center",
    pointSize: 1.3,
    subText: "Yearly capacity 2000 kg c.a.",
    bodyText:
      "Since 2018 we have marketed the best hemp genetics \nfor an outdoor set up. Emphasizing sustainability, \noutdoor production relies mainly on natural sunlight \nand organic farming practices to ensure high-quality, \neco-friendly cannabis production.",
  });

  // SECTION 2 INDOOR
  const section2IndoorTextDetails = useRef({
    isAdded: false,
    text: "INDOOR",
    align: "center",
    pointSize: 1.3,
    subText: "",
    bodyText:
      "An indoor cannabis facility is designed for precise control \nover all aspects of plant growth. It features \nclimate-controlled rooms with high-intensity grow lights to \nsimulate natural sunlight. The facility is equipped with \nadvanced HVAC systems for temperature and humidity \nregulation, as well as CO2 enrichment to enhance plant \ndevelopment. This setup ensures consistent, high-quality \nyields and year-round production while protecting plants \nfrom pests and external contaminants.",
  });

  // SECTION 2 GREEN HOUSE
  const section2GreenHouseTextDetails = useRef({
    isAdded: false,
    text: "GREEN HOUSE",
    align: "center",
    pointSize: 1.3,
    subText: "Yearly capacity 3000 kg c.a.",
    bodyText:
      "Since 2020 we selected the best genetics for greenhouse \nand glasshouse facilities.These structures combine \nnatural sunlight with controlled environmental conditions to \noptimize plant growth. The structure features transparent \nor translucent black-out walls and roofs to maximize light \nexposure, complemented by advanced climate control \nsystems for temperature, humidity, and ventilation. \nThis setup ensures consistent, high-quality yields while \nprotecting plants from pests and harsh weather.",
  });

  // section 3 details
  const section3TextDetails = useRef({
    isAdded: false,
    text: "DRIVEN BY PASSION",
    align: "center",
    pointSize: 1,
    subText: "We only commit to excellence",
    bodyText:
      "We curate the finest selections of CBD products prioritizing quality at every step of the process. From seed \nto harvest, our hemp flowers are organically grown with different setups and features and undergo rigorous \ntesting by third-party laboratories. Transparency and integrity are at the core of everything we do.",
  });

  // section 4 details
  const section4TextDetails = useRef({
    isAdded: false,
    text: "WE ARE YOUR\nTRUSTED SUPPLIER",
    align: "center",
    pointSize: 0.9,
    subText: "Dalai offers complementary services to ease\nyour operations.",
    bodyText:
      "Since 2021 we set quality control procedures, filling and \npacking services and THC reduction treatments for all \nproducts we distribute. Dalai is consistently becoming the \nstandard for high quality products and assurance on \nprocedures and services.",
  });

  // section 5 details
  const section5TextDetails = useRef({
    isAdded: false,
    text: "DALAI SRL",
    align: "center",
    pointSize: 0.9,
    subText: "",
    bodyText: "",
  });

  useEffect(() => {
    if (width < 5.67) {
      setParticlesCount(60);

      // section 2
      section2TextDetails.current.position = [-width * 0.07, 0.22, 4.2];
      // section2TextDetails.current.particlesCount = 30;
      section2TextDetails.current.subTextPosition = [-width * 0.051, 0.12, 4.4];
      section2TextDetails.current.bodyTextPosition = [
        -width * 0.051,
        0.06,
        4.4,
      ];
      section2TextDetails.current.textScale = [0.0012, -0.0012, 0.005];
      section2TextDetails.current.subTextSize = 2.7;
      section2TextDetails.current.bodyTextSize = 2;
      section2TextDetails.current.subText =
        "Since 2018 we have explored \nCannabis Sativa and its versatility";
      section2TextDetails.current.bodyText =
        "We partner with the best producers to assure  \ntop quality selection of new phenotypes.This is \nwhy today we are able to market unique \nproducts.";
      section2TextDetails.current.pointSize = 0.9;

      // section 2 outdoor
      section2OutdoorTextDetails.current.position = [-width * 0.07, 0.22, 4.2];
      section2OutdoorTextDetails.current.subTextPosition = [
        -width * 0.051,
        0.12,
        4.4,
      ];
      section2OutdoorTextDetails.current.bodyTextPosition = [
        -width * 0.051,
        0.082,
        4.4,
      ];
      section2OutdoorTextDetails.current.textScale = [0.0012, -0.0012, 0.005];
      section2OutdoorTextDetails.current.subTextSize = 2.7;
      section2OutdoorTextDetails.current.bodyTextSize = 2;
      section2OutdoorTextDetails.current.subText =
        "Yearly capacity 2000 kg c.a.";
      section2OutdoorTextDetails.current.bodyText =
        "Since 2018 we have marketed the best \nhemp genetics for an outdoor set up. \nEmphasizing sustainability, outdoor \nproduction relies mainly on natural sunlight \nand organic farming practices to ensure \nhigh-quality, eco-friendly cannabis \nproduction.";
      section2OutdoorTextDetails.current.pointSize = 0.9;

      // section 2 indoor
      section2IndoorTextDetails.current.position = [-width * 0.07, 0.22, 4.2];
      section2IndoorTextDetails.current.subTextPosition = [
        -width * 0.051,
        0.12,
        4.4,
      ];
      section2IndoorTextDetails.current.bodyTextPosition = [
        -width * 0.051,
        0.12,
        4.4,
      ];
      section2IndoorTextDetails.current.textScale = [0.0012, -0.0012, 0.005];
      section2IndoorTextDetails.current.subTextSize = 2.7;
      section2IndoorTextDetails.current.bodyTextSize = 2;
      section2IndoorTextDetails.current.bodyText =
        "An indoor cannabis facility is designed for \nprecise control over all aspects of plant \ngrowth. It features climate-controlled rooms \nwith high-intensity grow lights to simulate \nnatural sunlight. The facility is equipped with \nadvanced HVAC systems for temperature and \nhumidity regulation, as well as CO2 enrichment \nto enhance plant development. This setup \nensures consistent, high-quality yields \nand year-round production while \nprotecting plants from pests and \nexternal contaminants.";
      section2IndoorTextDetails.current.pointSize = 0.9;

      // section 2 green house
      section2GreenHouseTextDetails.current.position = [
        -width * 0.07,
        0.22,
        4.2,
      ];
      section2GreenHouseTextDetails.current.subTextPosition = [
        -width * 0.051,
        0.12,
        4.4,
      ];
      section2GreenHouseTextDetails.current.bodyTextPosition = [
        -width * 0.051,
        0.08,
        4.4,
      ];
      section2GreenHouseTextDetails.current.textScale = [
        0.0012, -0.0012, 0.005,
      ];
      section2GreenHouseTextDetails.current.subTextSize = 2.7;
      section2GreenHouseTextDetails.current.bodyTextSize = 2;
      section2GreenHouseTextDetails.current.bodyText =
        "Since 2020 we selected the best genetics \nfor greenhouse and glasshouse facilities.These \nstructures combine natural sunlight with \ncontrolled environmental conditions to \noptimize plant growth. The structure features \ntransparent or translucent black-out walls \nand roofs to maximize light exposure, \ncomplemented by advanced climate control \nsystems for temperature, humidity, and \nventilation. This setup ensures consistent, \nhigh-quality yields while protecting \nplants from pests and harsh weather.";
      section2GreenHouseTextDetails.current.pointSize = 0.9;

      // section 3
      section3TextDetails.current.position = [-width * 0.085, -0.3, 4];
      section3TextDetails.current.subTextPosition = [-width * 0.085, -0.36, 4];
      section3TextDetails.current.bodyTextPosition = [-width * 0.085, -0.41, 4];
      section3TextDetails.current.textScale = [0.0013, -0.0013, 0.01];
      section3TextDetails.current.subTextSize = 3.8;
      section3TextDetails.current.bodyTextSize = 3;
      section3TextDetails.current.bodyText =
        "We curate the finest selections of CBD products prioritizing \nquality at every step of the process.From seed to harvest,  \nour hemp flowers are organically grown with different \nsetups and features and undergo rigorous testing by \nthird-party laboratories. Transparency and integrity \nare at the core of everything we do.";

      // section 4
      section4TextDetails.current.position = [-width * 0.095, 0.3, 4];
      section4TextDetails.current.subTextPosition = [-width * 0.094, 0.22, 4];
      section4TextDetails.current.bodyTextPosition = [-width * 0.094, 0.12, 4];
      section4TextDetails.current.textScale = [0.0012, -0.0012, 0.02];
      section4TextDetails.current.subTextSize = 3.6;
      section4TextDetails.current.bodyTextSize = 2.8;
      section4TextDetails.current.bodyText =
        "Since 2021 we have introduced quality control procedures,\nfilling and packing services and THC reduction treatments \nfor all products we want to distribute. Dalai is consistently \nbecoming the standard for high quality products and \nassurance on procedures and services.";

      // section 5
      section5TextDetails.current.position = [-width * 0.08, 0.4, 4];
      section5TextDetails.current.textScale = [0.0018, -0.0018, 0.01];
      section5TextDetails.current.subTextPosition = [-width * 0.094, 0, 4];
      section5TextDetails.current.bodyTextPosition = [-width * 0.094, 0, 4];
      section5TextDetails.current.subTextSize = 6;
      section5TextDetails.current.bodyTextSize = 4;
    } else if (width < 7.7 && width >= 5.67) {
      setParticlesCount(80);
      // section 2
      section2TextDetails.current.position = [-width * 0.084, 0.1, 4];
      section2TextDetails.current.subTextPosition = [-width * 0.084, 0.017, 4];
      section2TextDetails.current.bodyTextPosition = [-width * 0.084, -0.1, 4];
      section2TextDetails.current.textScale = [0.0017, -0.0017, 0.01];
      section2TextDetails.current.subTextSize = 5.2;
      section2TextDetails.current.bodyTextSize = 3.8;
      section2TextDetails.current.subText =
        "Since 2018 we have explored \nCannabis Sativa and its versatility";
      section2TextDetails.current.bodyText =
        "We partner with the best producers to assure  \ntop quality selection of new phenotypes.This is \nwhy today we are able to market unique products.";

      // section 2 outdoor
      section2OutdoorTextDetails.current.position = [-width * 0.084, 0.1, 4];
      section2OutdoorTextDetails.current.subTextPosition = [
        -width * 0.084,
        0.017,
        4,
      ];
      section2OutdoorTextDetails.current.bodyTextPosition = [
        -width * 0.084,
        -0.05,
        4,
      ];
      section2OutdoorTextDetails.current.textScale = [0.0017, -0.0017, 0.01];
      section2OutdoorTextDetails.current.subTextSize = 5.2;
      section2OutdoorTextDetails.current.bodyTextSize = 3.8;
      section2OutdoorTextDetails.current.subText =
        "Yearly capacity 2000 kg c.a.";
      section2OutdoorTextDetails.current.bodyText =
        "Since 2018 we have marketed the best \nhemp genetics for an outdoor set up. \nEmphasizing sustainability, outdoor \nproduction relies mainly on natural sunlight \nand organic farming practices to ensure \nhigh-quality, eco-friendly cannabis \nproduction.";

      // section 2 indoor
      section2IndoorTextDetails.current.position = [-width * 0.084, 0.1, 4];
      section2IndoorTextDetails.current.subTextPosition = [
        -width * 0.084,
        0.017,
        4,
      ];
      section2IndoorTextDetails.current.bodyTextPosition = [
        -width * 0.084,
        0.017,
        4,
      ];
      section2IndoorTextDetails.current.textScale = [0.0017, -0.0017, 0.01];
      section2IndoorTextDetails.current.subTextSize = 5.2;
      section2IndoorTextDetails.current.bodyTextSize = 3.8;
      section2IndoorTextDetails.current.bodyText =
        "An indoor cannabis facility is designed for \nprecise control over all aspects of plant growth. \nIt features climate-controlled rooms with  \nhigh-intensity grow lights to simulate natural \nsunlight. The facility is equipped with advanced \nHVAC systems for temperature and humidity \nregulation, as well as CO2 enrichment to enhance \nplant development. This setup ensures consistent, \nhigh-quality yields and year-round production \nwhile protecting plants from pests and \nexternal contaminants.";

      // section 2 greenhouse
      section2GreenHouseTextDetails.current.position = [-width * 0.084, 0.1, 4];
      section2GreenHouseTextDetails.current.subTextPosition = [
        -width * 0.084,
        0.017,
        4,
      ];
      section2GreenHouseTextDetails.current.bodyTextPosition = [
        -width * 0.084,
        -0.05,
        4,
      ];
      section2GreenHouseTextDetails.current.textScale = [0.0017, -0.0017, 0.01];
      section2GreenHouseTextDetails.current.subTextSize = 5.2;
      section2GreenHouseTextDetails.current.bodyTextSize = 3.8;
      section2GreenHouseTextDetails.current.bodyText =
        "Since 2020 we selected the best genetics \nfor greenhouse and glasshouse facilities.These \nstructures combine natural sunlight with \ncontrolled environmental conditions to \noptimize plant growth. The structure features \ntransparent or translucent black-out walls \nand roofs to maximize light exposure, \ncomplemented by advanced climate control \nsystems for temperature, humidity, and ventilation. \nThis setup ensures consistent, high-quality yields \nwhile protecting plants from pests and harsh weather.";

      // section 3
      section3TextDetails.current.position = [-width * 0.075, -0.4, 4];
      section3TextDetails.current.subTextPosition = [-width * 0.03, -0.48, 4];
      section3TextDetails.current.bodyTextPosition = [-width * 0.08, -0.53, 4];
      section3TextDetails.current.textScale = [0.002, -0.002, 0.01];
      section3TextDetails.current.subTextSize = 3.8;
      section3TextDetails.current.bodyTextSize = 2.5;

      // section 4
      section4TextDetails.current.position = [-width * 0.095, 0.3, 4];
      section4TextDetails.current.subTextPosition = [-width * 0.094, 0.22, 4];
      section4TextDetails.current.bodyTextPosition = [-width * 0.094, 0.12, 4];
      section4TextDetails.current.textScale = [0.0018, -0.0018, 0.02];
      section4TextDetails.current.subTextSize = 4.1;
      section4TextDetails.current.bodyTextSize = 2.8;

      // section 5
      section5TextDetails.current.position = [-width * 0.08, 0.5, 4];
      section5TextDetails.current.textScale = [0.0018, -0.0018, 0.01];
      section5TextDetails.current.subTextPosition = [-width * 0.094, 0, 4];
      section5TextDetails.current.bodyTextPosition = [-width * 0.094, 0, 4];
      section5TextDetails.current.subTextSize = 6;
      section5TextDetails.current.bodyTextSize = 4;
    } else if (width >= 7.7 && width < 11.34) {
      setParticlesCount(80);

      // section 2
      section2TextDetails.current.position = [-width * 0.13, 0.14, 3.5];
      section2TextDetails.current.subTextPosition = [-width * 0.13, 0.017, 3.5];
      section2TextDetails.current.bodyTextPosition = [-width * 0.0863, -0.1, 4];
      section2TextDetails.current.textScale = [0.003, -0.003, 0.015];
      section2TextDetails.current.subTextSize = 7;
      section2TextDetails.current.bodyTextSize = 4;
      section2TextDetails.current.bodyText =
        "We partner with the best producers to assure  \ntop quality selection of new phenotypes.This is \nwhy today we are able to market unique products.";

      // section 2 outdoor
      section2OutdoorTextDetails.current.position = [-width * 0.13, 0.14, 3.5];
      section2OutdoorTextDetails.current.subTextPosition = [
        -width * 0.13,
        0.017,
        3.5,
      ];
      section2OutdoorTextDetails.current.bodyTextPosition = [
        -width * 0.0863,
        -0.05,
        4,
      ];
      section2OutdoorTextDetails.current.textScale = [0.003, -0.003, 0.015];
      section2OutdoorTextDetails.current.subTextSize = 7;
      section2OutdoorTextDetails.current.bodyTextSize = 4;
      section2OutdoorTextDetails.current.subText =
        "Yearly capacity 2000 kg c.a.";
      section2OutdoorTextDetails.current.bodyText =
        "Since 2018 we have marketed the best hemp \ngenetics for an outdoor set up. Emphasizing \nsustainability, outdoor production relies \nmainly on natural sunlight and organic \nfarming practices to ensure high-quality, \neco-friendly cannabis production.";

      // section 2 indoor
      section2IndoorTextDetails.current.position = [-width * 0.13, 0.14, 3.5];
      section2IndoorTextDetails.current.subTextPosition = [
        -width * 0.13,
        0.017,
        3.5,
      ];
      section2IndoorTextDetails.current.bodyTextPosition = [
        -width * 0.0863,
        0.017,
        4,
      ];
      section2IndoorTextDetails.current.textScale = [0.003, -0.003, 0.015];
      section2IndoorTextDetails.current.subTextSize = 7;
      section2IndoorTextDetails.current.bodyTextSize = 4;
      section2IndoorTextDetails.current.bodyText =
        "An indoor cannabis facility is designed for \nprecise control over all aspects of plant growth. \nIt features climate-controlled rooms with  \nhigh-intensity grow lights to simulate natural \nsunlight. The facility is equipped with advanced \nHVAC systems for temperature and humidity \nregulation, as well as CO2 enrichment to enhance \nplant development. This setup ensures consistent, \nhigh-quality yields and year-round production \nwhile protecting plants from pests and \nexternal contaminants.";

      // section 2 greenhouse
      section2GreenHouseTextDetails.current.position = [
        -width * 0.13,
        0.14,
        3.5,
      ];
      section2GreenHouseTextDetails.current.subTextPosition = [
        -width * 0.13,
        0.017,
        3.5,
      ];
      section2GreenHouseTextDetails.current.bodyTextPosition = [
        -width * 0.0863,
        -0.05,
        4,
      ];
      section2GreenHouseTextDetails.current.textScale = [0.003, -0.003, 0.015];
      section2GreenHouseTextDetails.current.subTextSize = 7;
      section2GreenHouseTextDetails.current.bodyTextSize = 4;
      section2GreenHouseTextDetails.current.bodyText =
        "Since 2020 we selected the best genetics \nfor greenhouse and glasshouse facilities.These \nstructures combine natural sunlight with \ncontrolled environmental conditions to \noptimize plant growth. The structure features \ntransparent or translucent black-out walls \nand roofs to maximize light exposure, \ncomplemented by advanced climate control \nsystems for temperature, humidity, and ventilation. \nThis setup ensures consistent, high-quality yields \nwhile protecting plants from pests and harsh weather.";

      // section 3
      section3TextDetails.current.position = [-width * 0.06, -0.4, 4];
      section3TextDetails.current.subTextPosition = [-width * 0.035, -0.47, 4];
      section3TextDetails.current.bodyTextPosition = [-width * 0.065, -0.53, 4];
      section3TextDetails.current.textScale = [0.002, -0.002, 0.01];
      section3TextDetails.current.subTextSize = 4;
      section3TextDetails.current.bodyTextSize = 2.7;

      // section 4
      section4TextDetails.current.position = [-width * 0.095, 0.11, 4];
      section4TextDetails.current.subTextPosition = [-width * 0.094, 0, 4];
      section4TextDetails.current.bodyTextPosition = [-width * 0.094, -0.12, 4];
      section4TextDetails.current.textScale = [0.0022, -0.0022, 0.02];
      section4TextDetails.current.subTextSize = 4.5;
      section4TextDetails.current.bodyTextSize = 3;

      // section 5
      section5TextDetails.current.position = [-width * 0.095, -0.25, 4];
      section5TextDetails.current.textScale = [0.0018, -0.0018, 0.01];
      section5TextDetails.current.subTextPosition = [-width * 0.094, 0, 4];
      section5TextDetails.current.bodyTextPosition = [-width * 0.094, 0, 4];
      section5TextDetails.current.subTextSize = 6;
      section5TextDetails.current.bodyTextSize = 4;
    } else if (width >= 11.34 && width <= 14.5) {
      setParticlesCount(80);

      // section 2
      section2TextDetails.current.position = [-width * 0.13, 0.2, 3.5];
      section2TextDetails.current.subTextPosition = [-width * 0.13, 0.05, 3.5];
      section2TextDetails.current.bodyTextPosition = [-width * 0.0863, -0.1, 4];
      section2TextDetails.current.textScale = [0.0035, -0.0035, 0.02];
      section2TextDetails.current.subTextSize = 7.5;
      section2TextDetails.current.bodyTextSize = 4.2;

      // section 2 outdoor
      section2OutdoorTextDetails.current.position = [-width * 0.13, 0.2, 3.5];
      section2OutdoorTextDetails.current.subTextPosition = [
        -width * 0.13,
        0.06,
        3.5,
      ];
      section2OutdoorTextDetails.current.bodyTextPosition = [
        -width * 0.0863,
        -0.03,
        4,
      ];
      section2OutdoorTextDetails.current.textScale = [0.0035, -0.0035, 0.02];
      section2OutdoorTextDetails.current.subTextSize = 7.5;
      section2OutdoorTextDetails.current.bodyTextSize = 4.2;

      // section 2 indoor
      section2IndoorTextDetails.current.position = [-width * 0.13, 0.2, 3.5];
      section2IndoorTextDetails.current.subTextPosition = [
        -width * 0.13,
        0.05,
        3.5,
      ];
      section2IndoorTextDetails.current.bodyTextPosition = [
        -width * 0.0863,
        0.05,
        4,
      ];
      section2IndoorTextDetails.current.textScale = [0.0035, -0.0035, 0.02];
      section2IndoorTextDetails.current.subTextSize = 7.5;
      section2IndoorTextDetails.current.bodyTextSize = 4.2;

      // section 2 green house
      section2GreenHouseTextDetails.current.position = [
        -width * 0.13,
        0.2,
        3.5,
      ];
      section2GreenHouseTextDetails.current.subTextPosition = [
        -width * 0.13,
        0.05,
        3.5,
      ];
      section2GreenHouseTextDetails.current.bodyTextPosition = [
        -width * 0.0863,
        -0.05,
        4,
      ];
      section2GreenHouseTextDetails.current.textScale = [0.0035, -0.0035, 0.02];
      section2GreenHouseTextDetails.current.subTextSize = 7.5;
      section2GreenHouseTextDetails.current.bodyTextSize = 4.2;

      // section 3
      section3TextDetails.current.position = [-width * 0.05, -0.45, 4];
      section3TextDetails.current.subTextPosition = [-width * 0.02, -0.54, 4];
      section3TextDetails.current.bodyTextPosition = [-width * 0.05, -0.6, 4];
      section3TextDetails.current.textScale = [0.003, -0.003, 0.02];
      section3TextDetails.current.subTextSize = 6;
      section3TextDetails.current.bodyTextSize = 3.5;

      // section 4
      section4TextDetails.current.position = [-width * 0.095, 0.11, 4];
      section4TextDetails.current.subTextPosition = [-width * 0.094, 0, 4];
      section4TextDetails.current.bodyTextPosition = [-width * 0.094, -0.15, 4];
      section4TextDetails.current.textScale = [0.0022, -0.0022, 0.02];
      section4TextDetails.current.subTextSize = 5.5;
      section4TextDetails.current.bodyTextSize = 3.5;

      // section 5
      section5TextDetails.current.position = [-width * 0.095, -0.1, 4];
      section5TextDetails.current.textScale = [0.0023, -0.0023, 0.01];
      section5TextDetails.current.subTextPosition = [-width * 0.094, 0, 4];
      section5TextDetails.current.bodyTextPosition = [-width * 0.094, 0, 4];
      section5TextDetails.current.subTextSize = 6;
      section5TextDetails.current.bodyTextSize = 4;
    } else {
      setParticlesCount(80);

      // section 2
      section2TextDetails.current.position = [-width * 0.13, 0.2, 3.5];
      section2TextDetails.current.subTextPosition = [-width * 0.13, 0.05, 3.5];
      section2TextDetails.current.bodyTextPosition = [-width * 0.0863, -0.1, 4];
      section2TextDetails.current.textScale = [0.004, -0.004, 0.02];
      section2TextDetails.current.subTextSize = 8;
      section2TextDetails.current.bodyTextSize = 4.5;

      // section 2 indoor
      section2IndoorTextDetails.current.position = [-width * 0.13, 0.2, 3.5];
      section2IndoorTextDetails.current.subTextPosition = [
        -width * 0.13,
        0.05,
        3.5,
      ];
      section2IndoorTextDetails.current.bodyTextPosition = [
        -width * 0.0863,
        0.05,
        4,
      ];
      section2IndoorTextDetails.current.textScale = [0.004, -0.004, 0.02];
      section2IndoorTextDetails.current.subTextSize = 8;
      section2IndoorTextDetails.current.bodyTextSize = 4.5;

      // section 2 outdoor
      section2OutdoorTextDetails.current.position = [-width * 0.13, 0.2, 3.5];
      section2OutdoorTextDetails.current.subTextPosition = [
        -width * 0.13,
        0.05,
        3.5,
      ];
      section2OutdoorTextDetails.current.bodyTextPosition = [
        -width * 0.0863,
        -0.05,
        4,
      ];
      section2OutdoorTextDetails.current.textScale = [0.004, -0.004, 0.02];
      section2OutdoorTextDetails.current.subTextSize = 8;
      section2OutdoorTextDetails.current.bodyTextSize = 4.5;

      // section 2 greenhouse
      section2GreenHouseTextDetails.current.position = [
        -width * 0.13,
        0.2,
        3.5,
      ];
      section2GreenHouseTextDetails.current.subTextPosition = [
        -width * 0.13,
        0.05,
        3.5,
      ];
      section2GreenHouseTextDetails.current.bodyTextPosition = [
        -width * 0.0863,
        -0.05,
        4,
      ];
      section2GreenHouseTextDetails.current.textScale = [0.004, -0.004, 0.02];
      section2GreenHouseTextDetails.current.subTextSize = 8;
      section2GreenHouseTextDetails.current.bodyTextSize = 4.5;

      // section 3
      section3TextDetails.current.position = [-width * 0.044, -0.45, 4];
      section3TextDetails.current.subTextPosition = [-width * 0.02, -0.54, 4];
      section3TextDetails.current.bodyTextPosition = [-width * 0.044, -0.6, 4];
      section3TextDetails.current.textScale = [0.003, -0.003, 0.02];
      section3TextDetails.current.subTextSize = 6;
      section3TextDetails.current.bodyTextSize = 3.5;

      // section 4
      section4TextDetails.current.position = [-width * 0.095, 0.11, 4];
      section4TextDetails.current.subTextPosition = [-width * 0.094, 0, 4];
      section4TextDetails.current.bodyTextPosition = [-width * 0.094, -0.15, 4];
      section4TextDetails.current.textScale = [0.0026, -0.0026, 0.02];
      section4TextDetails.current.subTextSize = 6;
      section4TextDetails.current.bodyTextSize = 4;

      // section 5
      section5TextDetails.current.position = [-width * 0.095, -0.1, 4];
      section5TextDetails.current.textScale = [0.0024, -0.0024, 0.01];
      section5TextDetails.current.subTextPosition = [-width * 0.094, 0, 4];
      section5TextDetails.current.bodyTextPosition = [-width * 0.094, 0, 4];
      section5TextDetails.current.subTextSize = 6;
      section5TextDetails.current.bodyTextSize = 4;
    }
  }, [width]);

  // for text movement
  useFrame(({ clock }) => {
    if (generatedTextMesh) {
      generatedTextMesh.uniforms.time.value = clock.getElapsedTime();
    }
    if (generatedPoints) {
      generatedPoints.uniforms.time.value = clock.getElapsedTime();
    }
  });

  // to remove individual section text
  const removeSectionText = (sectionName) => {
    const keysToDispose = ["subTextuuid", "uuid", "bodyTextuuid"];
    keysToDispose.forEach(async (element) => {
      const object = await scene.getObjectByProperty(
        "uuid",
        sectionName.current[element]
      );
      if (object) {
        // object.geometry.dispose();
        // object.material.dispose();
        if (object.parent === scene.children[1]) {
          scene.children[1].remove(object); // Remove from specific container
        } else {
          scene.remove(object); // Remove from the scene if not in scene.children[1]
        }
      }
    });
    activeText.current = null;
  };

  useFrame(() => {
    const currentScroll = scroll.current * 100;
    // if (currentScroll < 23 || currentScroll > 96) {
    //   removeAllSectionText();
    // }

    // add section 2 text
    if (currentScroll > 30 && currentScroll < 48) {
      if (
        !section2TextDetails.current.isAdded &&
        currentScroll > 30 &&
        currentScroll < 48 &&
        !showPlayButtons &&
        isTextShouldAdd() &&
        !isTextAddInProgress.current
      ) {
        const object = scene.getObjectByProperty(
          "uuid",
          section2TextDetails.current.uuid
        );
        // if (!object) {
        scene.add(section2TextDetails.current.textMesh);
        scene.add(section2TextDetails.current.subTextMesh);
        scene.add(section2TextDetails.current.bodyTextMesh);
        // }
        isTextAddInProgress.current = true;
        section2TextDetails.current.isAdded = true;
        subTextMaterial.current = section2TextDetails.current.subTextMaterial;
        setGeneratedTextMesh(section2TextDetails.current.material);
        bodyTextMaterial.current = section2TextDetails.current.bodyTextMaterial;
        increaseTextOpacity();
        scene.add(section2TextDetails.current.points);
        activeText.current = section2TextDetails.current;
      }
    }
    if (
      section2TextDetails.current.isAdded &&
      !isTextRemoveInProgress.current &&
      (showPlayButtons || currentScroll > 48 || currentScroll < 30)
    ) {
      if (section2TextDetails.current.isAdded) {
        const object = scene.getObjectByProperty(
          "uuid",
          section2TextDetails.current.uuid2
        );
        if (object) {
          scene.remove(object);
        }
        removeTextCheckFunction(section2TextDetails);
      }
    }

    // add section 2 outdoor text
    if (currentScroll > 30 && currentScroll < 48) {
      if (
        !section2OutdoorTextDetails.current.isAdded &&
        currentScroll > 30 &&
        currentScroll < 48 &&
        showPlayButtons &&
        isTextShouldAdd() &&
        activeMode === "outdoor" &&
        !isTextAddInProgress.current
      ) {
        const object = scene.getObjectByProperty(
          "uuid",
          section2OutdoorTextDetails.current.uuid
        );
        // if (!object) {
        scene.add(section2OutdoorTextDetails.current.textMesh);
        scene.add(section2OutdoorTextDetails.current.subTextMesh);
        scene.add(section2OutdoorTextDetails.current.bodyTextMesh);
        // }
        isTextAddInProgress.current = true;
        section2OutdoorTextDetails.current.isAdded = true;
        subTextMaterial.current =
          section2OutdoorTextDetails.current.subTextMaterial;
        setGeneratedTextMesh(section2OutdoorTextDetails.current.material);
        bodyTextMaterial.current =
          section2OutdoorTextDetails.current.bodyTextMaterial;
        increaseTextOpacity();
        scene.add(section2OutdoorTextDetails.current.points);
        activeText.current = section2OutdoorTextDetails.current;
      }
    }

    if (
      ((showPlayButtons && (currentScroll > 48 || currentScroll < 30)) ||
        activeMode !== "outdoor" ||
        !showPlayButtons) &&
      section2OutdoorTextDetails.current.isAdded &&
      !isTextRemoveInProgress.current
    ) {
      if (section2OutdoorTextDetails.current.isAdded) {
        const object = scene.getObjectByProperty(
          "uuid",
          section2OutdoorTextDetails.current.uuid2
        );
        if (object) {
          scene.remove(object);
        }
        removeTextCheckFunction(section2OutdoorTextDetails);
      }
    }

    // add section 2 green text
    if (currentScroll > 30 && currentScroll < 48) {
      if (
        !section2GreenHouseTextDetails.current.isAdded &&
        currentScroll > 30 &&
        currentScroll < 48 &&
        showPlayButtons &&
        isTextShouldAdd() &&
        activeMode === "green" &&
        !isTextAddInProgress.current
      ) {
        const object = scene.getObjectByProperty(
          "uuid",
          section2GreenHouseTextDetails.current.uuid
        );
        // if (!object) {
        scene.add(section2GreenHouseTextDetails.current.textMesh);
        scene.add(section2GreenHouseTextDetails.current.subTextMesh);
        scene.add(section2GreenHouseTextDetails.current.bodyTextMesh);
        // }
        isTextAddInProgress.current = true;
        section2GreenHouseTextDetails.current.isAdded = true;
        subTextMaterial.current =
          section2GreenHouseTextDetails.current.subTextMaterial;
        setGeneratedTextMesh(section2GreenHouseTextDetails.current.material);
        bodyTextMaterial.current =
          section2GreenHouseTextDetails.current.bodyTextMaterial;
        increaseTextOpacity();
        scene.add(section2GreenHouseTextDetails.current.points);
        activeText.current = section2GreenHouseTextDetails.current;
      }
    }

    if (
      ((showPlayButtons && (currentScroll > 48 || currentScroll < 30)) ||
        activeMode !== "green" ||
        !showPlayButtons) &&
      section2GreenHouseTextDetails.current.isAdded &&
      !isTextRemoveInProgress.current
    ) {
      if (section2GreenHouseTextDetails.current.isAdded) {
        const object = scene.getObjectByProperty(
          "uuid",
          section2GreenHouseTextDetails.current.uuid2
        );
        if (object) {
          scene.remove(object);
        }
        removeTextCheckFunction(section2GreenHouseTextDetails);
      }
    }

    // add section 2 indoor text
    if (currentScroll > 30 && currentScroll < 48) {
      if (
        !section2IndoorTextDetails.current.isAdded &&
        currentScroll > 30 &&
        currentScroll < 48 &&
        showPlayButtons &&
        isTextShouldAdd() &&
        activeMode === "indoor" &&
        !isTextAddInProgress.current
      ) {
        const object = scene.getObjectByProperty(
          "uuid",
          section2IndoorTextDetails.current.uuid
        );
        // if (!object) {
        scene.add(section2IndoorTextDetails.current.textMesh);
        scene.add(section2IndoorTextDetails.current.subTextMesh);
        scene.add(section2IndoorTextDetails.current.bodyTextMesh);
        // }
        isTextAddInProgress.current = true;
        section2IndoorTextDetails.current.isAdded = true;
        subTextMaterial.current =
          section2IndoorTextDetails.current.subTextMaterial;
        setGeneratedTextMesh(section2IndoorTextDetails.current.material);
        bodyTextMaterial.current =
          section2IndoorTextDetails.current.bodyTextMaterial;
        increaseTextOpacity();
        scene.add(section2IndoorTextDetails.current.points);
        activeText.current = section2IndoorTextDetails.current;
      }
    }

    if (
      ((showPlayButtons && (currentScroll > 48 || currentScroll < 30)) ||
        activeMode !== "indoor" ||
        !showPlayButtons) &&
      section2IndoorTextDetails.current.isAdded &&
      !isTextRemoveInProgress.current
    ) {
      if (section2IndoorTextDetails.current.isAdded) {
        const object = scene.getObjectByProperty(
          "uuid",
          section2IndoorTextDetails.current.uuid2
        );
        if (object) {
          scene.remove(object);
        }
        removeTextCheckFunction(section2IndoorTextDetails);
      }
    }

    // add section 3 text
    if (width > 7.7) {
      if (currentScroll > 60.5 && currentScroll < 65) {
        if (
          section3TextDetails.current.isAdded &&
          !isTextAddInProgress.current &&
          isTextRemoveInProgress.current
        ) {
          increaseTextOpacity();
          scene.add(section3TextDetails.current.points);
        }
        if (
          textOpacity == 0 &&
          section3TextDetails.current.isAdded &&
          !isTextAddInProgress.current &&
          !isTextRemoveInProgress.current
        ) {
          increaseTextOpacity();
        }
        if (
          !section3TextDetails.current.isAdded &&
          currentScroll > 60 &&
          isTextShouldAdd() &&
          !isTextAddInProgress.current
        ) {
          const object = scene.getObjectByProperty(
            "uuid",
            section3TextDetails.current.uuid
          );
          // if (!object) {
          scene.add(section3TextDetails.current.textMesh);
          scene.add(section3TextDetails.current.subTextMesh);
          scene.add(section3TextDetails.current.bodyTextMesh);
          // }
          isTextRemoveInProgress.current = false;
          isTextAddInProgress.current = true;
          section3TextDetails.current.isAdded = true;
          subTextMaterial.current = section3TextDetails.current.subTextMaterial;
          setGeneratedTextMesh(section3TextDetails.current.material);
          bodyTextMaterial.current =
            section3TextDetails.current.bodyTextMaterial;
          increaseTextOpacity();
          scene.add(section3TextDetails.current.points);
          activeText.current = section3TextDetails.current;
        }
      }
    } else {
      if (currentScroll > 60 && currentScroll < 65) {
        if (
          section3TextDetails.current.isAdded &&
          !isTextAddInProgress.current &&
          isTextRemoveInProgress.current
        ) {
          increaseTextOpacity();
          scene.add(section3TextDetails.current.points);
        }
        if (
          textOpacity == 0 &&
          section3TextDetails.current.isAdded &&
          !isTextAddInProgress.current &&
          !isTextRemoveInProgress.current
        ) {
          increaseTextOpacity();
        }
        if (
          !section3TextDetails.current.isAdded &&
          currentScroll > 60 &&
          isTextShouldAdd() &&
          !isTextAddInProgress.current
        ) {
          const object = scene.getObjectByProperty(
            "uuid",
            section3TextDetails.current.uuid
          );
          // if (!object) {
          scene.add(section3TextDetails.current.textMesh);
          scene.add(section3TextDetails.current.subTextMesh);
          scene.add(section3TextDetails.current.bodyTextMesh);
          // }
          isTextRemoveInProgress.current = false;
          isTextAddInProgress.current = true;
          section3TextDetails.current.isAdded = true;
          subTextMaterial.current = section3TextDetails.current.subTextMaterial;
          setGeneratedTextMesh(section3TextDetails.current.material);
          bodyTextMaterial.current =
            section3TextDetails.current.bodyTextMaterial;
          increaseTextOpacity();
          scene.add(section3TextDetails.current.points);
          activeText.current = section3TextDetails.current;
        }
      }
    }
    if (width > 7.7) {
      if (
        (currentScroll > 65 || currentScroll < 60.5) &&
        section3TextDetails.current.isAdded &&
        !isTextRemoveInProgress.current
      ) {
        const object = scene.getObjectByProperty(
          "uuid",
          section3TextDetails.current.uuid2
        );
        if (object) {
          scene.remove(object);
        }
        removeTextCheckFunction(section3TextDetails);
      }
    } else {
      if (
        (currentScroll > 65 || currentScroll < 60) &&
        section3TextDetails.current.isAdded &&
        !isTextRemoveInProgress.current
      ) {
        const object = scene.getObjectByProperty(
          "uuid",
          section3TextDetails.current.uuid2
        );
        if (object) {
          scene.remove(object);
        }
        removeTextCheckFunction(section3TextDetails);
      }
    }

    // add section 4 text
    if (width < 7.7) {
      if (currentScroll > 67 && currentScroll < 73) {
        if (
          textOpacity == 0 &&
          section4TextDetails.current.isAdded &&
          !isTextAddInProgress.current &&
          !isTextRemoveInProgress.current
        ) {
          increaseTextOpacity();
        }
        if (
          section4TextDetails.current.isAdded &&
          !isTextAddInProgress.current &&
          isTextRemoveInProgress.current
        ) {
          increaseTextOpacity();
        }
        if (
          textOpacity == 0 &&
          section4TextDetails.current.isAdded &&
          !isTextAddInProgress.current &&
          !isTextRemoveInProgress.current
        ) {
          increaseTextOpacity();
        }
        if (
          !section4TextDetails.current.isAdded &&
          currentScroll > 67 &&
          isTextShouldAdd() &&
          !isTextAddInProgress.current
        ) {
          const object = scene.getObjectByProperty(
            "uuid",
            section4TextDetails.current.uuid
          );
          // if (!object) {
          isTextRemoveInProgress.current = false;

          scene.add(section4TextDetails.current.textMesh);
          scene.add(section4TextDetails.current.subTextMesh);
          scene.add(section4TextDetails.current.bodyTextMesh);
          // }
          isTextAddInProgress.current = true;
          section4TextDetails.current.isAdded = true;
          subTextMaterial.current = section4TextDetails.current.subTextMaterial;
          setGeneratedTextMesh(section4TextDetails.current.material);
          bodyTextMaterial.current =
            section4TextDetails.current.bodyTextMaterial;
          increaseTextOpacity();
          scene.add(section4TextDetails.current.points);
          activeText.current = section4TextDetails.current;
        }
      }
    } else {
      if (currentScroll > 67 && currentScroll < 78) {
        if (
          textOpacity == 0 &&
          section4TextDetails.current.isAdded &&
          !isTextAddInProgress.current &&
          !isTextRemoveInProgress.current
        ) {
          increaseTextOpacity();
        }
        if (
          section4TextDetails.current.isAdded &&
          !isTextAddInProgress.current &&
          isTextRemoveInProgress.current
        ) {
          increaseTextOpacity();
        }
        if (
          textOpacity == 0 &&
          section4TextDetails.current.isAdded &&
          !isTextAddInProgress.current &&
          !isTextRemoveInProgress.current
        ) {
          increaseTextOpacity();
        }
        if (
          !section4TextDetails.current.isAdded &&
          currentScroll > 67 &&
          isTextShouldAdd() &&
          !isTextAddInProgress.current
        ) {
          const object = scene.getObjectByProperty(
            "uuid",
            section4TextDetails.current.uuid
          );
          // if (!object) {
          isTextRemoveInProgress.current = false;

          scene.add(section4TextDetails.current.textMesh);
          scene.add(section4TextDetails.current.subTextMesh);
          scene.add(section4TextDetails.current.bodyTextMesh);
          // }
          isTextAddInProgress.current = true;
          section4TextDetails.current.isAdded = true;
          subTextMaterial.current = section4TextDetails.current.subTextMaterial;
          setGeneratedTextMesh(section4TextDetails.current.material);
          bodyTextMaterial.current =
            section4TextDetails.current.bodyTextMaterial;
          increaseTextOpacity();
          scene.add(section4TextDetails.current.points);
          activeText.current = section4TextDetails.current;
        }
      }
    }

    if (width < 7.7) {
      if (currentScroll > 73 || currentScroll < 67) {
        if (
          section4TextDetails.current.isAdded &&
          !isTextRemoveInProgress.current
        ) {
          const object = scene.getObjectByProperty(
            "uuid",
            section4TextDetails.current.uuid2
          );
          if (object) {
            scene.remove(object);
          }
          removeTextCheckFunction(section4TextDetails);
        }
      }
    } else {
      if (currentScroll > 78 || currentScroll < 67) {
        if (
          section4TextDetails.current.isAdded &&
          !isTextRemoveInProgress.current
        ) {
          const object = scene.getObjectByProperty(
            "uuid",
            section4TextDetails.current.uuid2
          );
          if (object) {
            scene.remove(object);
          }
          removeTextCheckFunction(section4TextDetails);
        }
      }
    }
    // }

    // add section 5 text
    if (width > 7.7) {
      if (currentScroll > 78 && currentScroll < 88) {
        if (
          section5TextDetails.current.isAdded &&
          !isTextAddInProgress.current &&
          isTextRemoveInProgress.current
        ) {
          increaseTextOpacity();
          scene.add(section5TextDetails.current.points);
        }
        if (
          textOpacity == 0 &&
          section5TextDetails.current.isAdded &&
          !isTextAddInProgress.current &&
          !isTextRemoveInProgress.current
        ) {
          increaseTextOpacity();
        }
        if (
          !section5TextDetails.current.isAdded &&
          currentScroll > 78 &&
          isTextShouldAdd() &&
          !isTextAddInProgress.current
        ) {
          const object = scene.getObjectByProperty(
            "uuid",
            section5TextDetails.current.uuid
          );
          // if (!object) {
          scene.add(section5TextDetails.current.textMesh);
          scene.add(section5TextDetails.current.subTextMesh);
          scene.add(section5TextDetails.current.bodyTextMesh);
          // }
          isTextRemoveInProgress.current = false;
          isTextAddInProgress.current = true;
          section5TextDetails.current.isAdded = true;
          subTextMaterial.current = section5TextDetails.current.subTextMaterial;
          setGeneratedTextMesh(section5TextDetails.current.material);
          bodyTextMaterial.current =
            section5TextDetails.current.bodyTextMaterial;
          increaseTextOpacity();
          scene.add(section5TextDetails.current.points);
          activeText.current = section5TextDetails.current;
        }
      }
    } else {
      if (currentScroll > 78 && currentScroll < 81.8) {
        if (
          section5TextDetails.current.isAdded &&
          !isTextAddInProgress.current &&
          isTextRemoveInProgress.current
        ) {
          increaseTextOpacity();
          scene.add(section5TextDetails.current.points);
        }
        if (
          textOpacity == 0 &&
          section5TextDetails.current.isAdded &&
          !isTextAddInProgress.current &&
          !isTextRemoveInProgress.current
        ) {
          increaseTextOpacity();
        }
        if (
          !section5TextDetails.current.isAdded &&
          currentScroll > 78 &&
          isTextShouldAdd() &&
          !isTextAddInProgress.current
        ) {
          const object = scene.getObjectByProperty(
            "uuid",
            section5TextDetails.current.uuid
          );
          // if (!object) {
          scene.add(section5TextDetails.current.textMesh);
          scene.add(section5TextDetails.current.subTextMesh);
          scene.add(section5TextDetails.current.bodyTextMesh);
          // }
          isTextRemoveInProgress.current = false;
          isTextAddInProgress.current = true;
          section5TextDetails.current.isAdded = true;
          subTextMaterial.current = section5TextDetails.current.subTextMaterial;
          setGeneratedTextMesh(section5TextDetails.current.material);
          bodyTextMaterial.current =
            section5TextDetails.current.bodyTextMaterial;
          increaseTextOpacity();
          scene.add(section5TextDetails.current.points);
          activeText.current = section5TextDetails.current;
        }
      }
    }

    if (width > 7.7) {
      if (
        (currentScroll > 88 || currentScroll < 78) &&
        section5TextDetails.current.isAdded &&
        !isTextRemoveInProgress.current
      ) {
        const object = scene.getObjectByProperty(
          "uuid",
          section5TextDetails.current.uuid2
        );
        if (object) {
          scene.remove(object);
        }
        removeTextCheckFunction(section5TextDetails);
      }
    } else {
      if (
        (currentScroll > 81.8 || currentScroll < 78) &&
        section5TextDetails.current.isAdded &&
        !isTextRemoveInProgress.current
      ) {
        const object = scene.getObjectByProperty(
          "uuid",
          section5TextDetails.current.uuid2
        );
        if (object) {
          scene.remove(object);
        }
        removeTextCheckFunction(section5TextDetails);
      }
    }
  });

  // for loading fontTexture
  useEffect(() => {
    const loadFontTexture = async () => {
      try {
        const texture = await new THREE.TextureLoader().loadAsync(
          "/font/manifold.png"
        );
        setLoading("font", true);

        setFontTexture(texture);
        const loader = new FontLoader();

        loader.load("/font/manifold.json", function (font) {
          setFont(font);
          setLoading("section5json", true);
        });

        loader.load("/font/Neulis Alt Light_Light.json", function (font) {
          setNeulisAltFont(font);
          setLoading("section5font", true);
        });

        loader.load("/font/Neulis Alt Medium_Medium.json", function (font) {
          setNeulisAltFontMed(font);
          setLoading("section5font", true);
        });

        loader.load(
          "https://threejs.org/examples/fonts/helvetiker_regular.typeface.json",
          function (font) {
            setHelvetikerFont(font);
            setLoading("section5font", true);
          }
        );
      } catch (error) {
        console.error("Error loading font texture:", error);
      }
    };
    loadFontTexture();
  }, []);

  useEffect(() => {
    if (font && neulisAltFont && fontTexture && helvetikerFont) {
      if (width > 5.67) {
        addText(section2TextDetails, neulisAltFont, 0xffffff);
        addText(section2IndoorTextDetails, neulisAltFont, 0xffffff);
        addText(section2OutdoorTextDetails, neulisAltFont, 0xffffff);
        addText(section2GreenHouseTextDetails, neulisAltFont, 0xffffff);
        addText(section3TextDetails, helvetikerFont, 0x7888b7);
        addText(section4TextDetails, helvetikerFont, 0x7888b7);
        addText(section5TextDetails, helvetikerFont, 0x7888b7);
      }
      else {
        addText(section2TextDetails, neulisAltFontMed, 0xffffff);
        addText(section2IndoorTextDetails, neulisAltFontMed, 0xffffff);
        addText(section2OutdoorTextDetails, neulisAltFontMed, 0xffffff);
        addText(section2GreenHouseTextDetails, neulisAltFontMed, 0xffffff);
        addText(section3TextDetails, helvetikerFont, 0x7888b7);
        addText(section4TextDetails, helvetikerFont, 0x7888b7);
        addText(section5TextDetails, helvetikerFont, 0x7888b7);
      }
    }
  }, [fontTexture, font, neulisAltFont, helvetikerFont]);

  // mouse events
  const setupMouseEvents = (materialText, material) => {
    const handleMouseMove = (event) => {
      const mouse = {
        x: event.clientX / window.innerWidth,
        y: event.clientY / window.innerHeight,
      };
      materialText.uniforms.uMouse.value = new THREE.Vector2(mouse.x, mouse.y);
      material.uniforms.uMouse.value = new THREE.Vector2(mouse.x, mouse.y);
    };
    const handleTouchMove = (event) => {
      const mouse = {
        x: event.touches[0].clientX / window.innerWidth,
        y: event.touches[0].clientY / window.innerHeight,
      };
      materialText.uniforms.uMouse.value = new THREE.Vector2(mouse.x, mouse.y);
      material.uniforms.uMouse.value = new THREE.Vector2(mouse.x, mouse.y);
    };
    const canvas = document.querySelector("canvas");
    const handleWindowResize = (event) => {
      materialText.uniforms.viewport.value = new THREE.Vector2(
        canvas.width,
        canvas.height
      );
      material.uniforms.viewport.value = new THREE.Vector2(
        canvas.width,
        canvas.height
      );
    };
    const handleTouchEnd = () => {
      materialText.uniforms.uMouse.value = new THREE.Vector2(0, 0);
      material.uniforms.uMouse.value = new THREE.Vector2(0, 0);
    };
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("touchmove", handleTouchMove);
    window.addEventListener("touchend", handleTouchEnd);
  };

  useFrame(() => {
    if (
      subTextMaterial.current &&
      bodyTextMaterial.current &&
      generatedTextMesh
    ) {
      if (textOpacity < 1) {
        subTextMaterial.current.transparent = true;
        subTextMaterial.current.depthWrite = false;
        bodyTextMaterial.current.transparent = true;
        bodyTextMaterial.current.depthWrite = false;
      } else {
        subTextMaterial.current.transparent = false;
        subTextMaterial.current.depthWrite = true;
        bodyTextMaterial.current.transparent = false;
        bodyTextMaterial.current.depthWrite = true;
      }
      subTextMaterial.current.opacity = textOpacity;
      subTextMaterial.current.needsUpdate = true;
      bodyTextMaterial.current.opacity = textOpacity;
      bodyTextMaterial.current.needsUpdate = true;
      generatedTextMesh.uniforms.opacity.value = textOpacity;
      if (
        textOpacity == 0 &&
        (subTextMaterial.current.opacity == 0 ||
          generatedTextMesh.uniforms.opacity.value == 0 ||
          subTextMaterial.current.opacity == 0) &&
        !isTextAddInProgress.current
      ) {
        setGeneratedTextMesh(null);
        subTextMaterial.current = null;
        bodyTextMaterial.current = null;
        isTextRemoveInProgress.current = false;
        activeText.current.isAdded = false;
        removeSectionText(activeText);
        // removeSectionText(activeText);
      }
    }
  });

  // addText
  const addText = (textDetails, fontType, color) => {
    isTextRemoveInProgress.current = false;
    const geometry = TextGeometryComponent({
      text: textDetails.current.text,
      font: font,
      align: textDetails.current.align,
      flipY: fontTexture.flipY,
      scene,
    });

    const material = new THREE.RawShaderMaterial(
      MSDFShader({
        map: fontTexture,
        transparent: true,
        color: color,

        side: THREE.DoubleSide,
        glslVersion: THREE.GLSL3,
        depthTest: true,
        depthWrite: true,
        opacity: 0,
        toneMapped: false,
      })
    );

    let layout = geometry.geometry.layout;
    let textMesh = new THREE.Mesh(geometry.bufferGeometry, material);

    textDetails.current.material = material;
    textMesh.scale.set(...textDetails.current.textScale);
    textMesh.position.set(...textDetails.current.position);

    textDetails.current.uuid = textMesh.uuid;

    const textGeometry = new TextGeometry(textDetails?.current?.subText, {
      font: fontType,
      size: textDetails.current.subTextSize,
      height: 4,
    });

    const textMaterial = new THREE.MeshBasicMaterial({
      color: color,
      transparent: true,
      opacity: 0,
      toneMapped: false,
    });

    const textMesh1 = new THREE.Mesh(textGeometry, textMaterial);
    textMesh1.scale.set(0.006, 0.006, 0);
    textMesh1.position.set(...textDetails.current.subTextPosition);
    // subTextMaterial.current = textMaterial;
    textDetails.current.subTextMaterial = textMaterial;
    textDetails.current.subTextuuid = textMesh1.uuid;
    textDetails.current.subTextMesh = textMesh1;

    const textGeometry2 = new TextGeometry(textDetails.current.bodyText, {
      font: fontType,
      size: textDetails.current.bodyTextSize,
      height: 4,
    });

    const textMaterial2 = new THREE.MeshBasicMaterial({
      color: color,
      transparent: true,
      opacity: 0,
      toneMapped: false,
    });
    const textMesh2 = new THREE.Mesh(textGeometry2, textMaterial2);
    textMesh2.scale.set(0.006, 0.006, 0);
    textMesh2.position.set(...textDetails.current.bodyTextPosition);

    // if (
    //   width < 7.7 &&
    //   (section2TextDetails.current.isAdded ||
    //     section3TextDetails.current.isAdded ||
    //     section4TextDetails.current.isAdded)
    // ) {
    //   scene.children[1].add(textMesh);
    //   scene.children[1].add(textMesh1);
    //   scene.children[1].add(textMesh2);
    // } else {
    //   scene.add(textMesh);
    //   scene.add(textMesh1);
    //   scene.add(textMesh2);
    // }

    // increaseTextOpacity();
    // bodyTextMaterial.current = textMaterial2;
    textDetails.current.bodyTextMaterial = textMaterial2;
    textDetails.current.bodyTextuuid = textMesh2.uuid;
    textDetails.current.textMesh = textMesh;
    textDetails.current.bodyTextMesh = textMesh2;

    // setGeneratedTextMesh(textMesh);
    setupMouseEvents(textMesh.material, material);
    addObjects(layout, textMesh, textDetails, color);
  };

  // addObjects
  const addObjects = (layout, textMesh, textDetails, color) => {
    const textBoundingBox = new THREE.Box3().setFromObject(textMesh);

    let number = textDetails?.current.particlesCount
      ? textDetails?.current.particlesCount
      : particlesCount;
    let geo = new THREE.BufferGeometry();
    let pos = [];

    for (let i = 0; i < number; i++) {
      // Generate random positions within the bounding box of the text mesh
      let x = THREE.MathUtils.randFloat(
        textBoundingBox.min.x,
        textBoundingBox.max.x
      );
      let y = THREE.MathUtils.randFloat(
        textBoundingBox.min.y,
        textBoundingBox.max.y
      );
      let z = THREE.MathUtils.randFloat(
        textBoundingBox.min.z,
        textBoundingBox.max.z
      );

      pos.push(x, y, z);
    }

    pos = new Float32Array(pos);
    geo.setAttribute("position", new THREE.BufferAttribute(pos, 3));

    const material = new THREE.ShaderMaterial({
      extensions: {
        derivatives: "#extension GL_OES_standard_derivatives : enable",
      },
      side: THREE.DoubleSide,
      uniforms: {
        time: { value: 0 },
        viewport: {
          value: new THREE.Vector2(window.innerWidth, window.innerHeight),
        },
        pointSize: { value: textDetails.current.pointSize },
        uMouse: { value: new THREE.Vector2(0, 0) },
        resolution: { value: new THREE.Vector4() },
        uColor: { value: new THREE.Color(color) },
      },
      depthTest: true,
      depthWrite: true,
      transparent: true,
      vertexShader: vertex,
      fragmentShader: fragment,
    });

    const points = new THREE.Points(geo, material);
    // setGeneratedPoints(points);
    textDetails.current.points = points;
    // scene.add(points);
    textDetails.current.uuid2 = points.uuid;
    addTimer(points);
    setupMouseEvents(textMesh.material, material);
  };

  // movement of particles
  const addTimer = (mesh) => {
    const boundingBox = new THREE.Box3().setFromObject(mesh);
    const areaBounds = {
      minX: boundingBox.min.x,
      maxX: boundingBox.max.x,
      minY: boundingBox.min.y,
      maxY: boundingBox.max.y,
      minZ: boundingBox.min.z,
      maxZ: boundingBox.max.z,
    };
    const maxVelocity = 0.05; // Maximum speed for particles
    const particlesCount = mesh.geometry.attributes.position.count;
    const velocities = new Array(particlesCount * 3)
      .fill(0)
      .map(() => (Math.random() - 0.5) * maxVelocity);

    setInterval(() => {
      const positions = mesh.geometry.attributes.position.array;

      for (let i = 0; i < positions.length; i++) {
        positions[i] += velocities[i];

        // Check and adjust position to stay within bounds
        if (i % 3 === 0) {
          // X-axis
          if (
            positions[i] < areaBounds.minX ||
            positions[i] > areaBounds.maxX
          ) {
            velocities[i] = -velocities[i]; // Reverse direction upon reaching boundary
          }
          positions[i] = Math.max(
            areaBounds.minX,
            Math.min(areaBounds.maxX, positions[i])
          );
        } else if (i % 3 === 1) {
          // Y-axis
          if (
            positions[i] < areaBounds.minY ||
            positions[i] > areaBounds.maxY
          ) {
            velocities[i] = -velocities[i]; // Reverse direction upon reaching boundary
          }
          positions[i] = Math.max(
            areaBounds.minY,
            Math.min(areaBounds.maxY, positions[i])
          );
        } else {
          // Z-axis
          if (
            positions[i] < areaBounds.minZ ||
            positions[i] > areaBounds.maxZ
          ) {
            velocities[i] = -velocities[i]; // Reverse direction upon reaching boundary
          }
          positions[i] = Math.max(
            areaBounds.minZ,
            Math.min(areaBounds.maxZ, positions[i])
          );
        }
      }
      // Update the buffer geometry to reflect changes
      mesh.geometry.attributes.position.needsUpdate = true;
    }, 100);
  };

  const isTextShouldAdd = () => {
    return (
      !generatedTextMesh &&
      !subTextMaterial.current &&
      !bodyTextMaterial.current
    );
  };

  const increaseTextOpacity = () => {
    clearInterval(textOpacityInterval.current);
    textOpacityInterval.current = null;
    isTextRemoveInProgress.current = false;
    const fadeIn = () => {
      setTextOpacity((prevOpacity) => {
        const newValue = Math.min(prevOpacity + 0.05, 1);
        if (newValue > 0.98) {
          clearInterval(textOpacityInterval.current);
          textOpacityInterval.current = null;
          isTextRemoveInProgress.current = false;
          isTextAddInProgress.current = false;
        }
        return newValue;
      });
    };

    textOpacityInterval.current = setInterval(() => {
      fadeIn();
    }, 100); // Change the interval as per your requirement
  };

  const removeTextCheckFunction = (textDetails) => {
    // if (isTextShouldRemove()) removeSectionText(textDetails);
    if (!isTextRemoveInProgress.current) {
      isTextRemoveInProgress.current = true;
      decreaseTextOpacity(textDetails);
    }
  };

  const decreaseTextOpacity = (textDetails) => {
    isTextRemoveInProgress.current = true;
    isTextAddInProgress.current = false;
    clearInterval(textOpacityInterval.current);
    textOpacityInterval.current = null;
    const fadeOut = () => {
      setTextOpacity((prevOpacity) => {
        const newValue = Math.max(prevOpacity - 0.15, 0);
        if (newValue == 0) {
          textDetails.current.isAdded = false;
          isTextRemoveInProgress.current = false;
          clearInterval(textOpacityInterval.current);
          textOpacityInterval.current = null;
        }
        return newValue;
      });
    };

    textOpacityInterval.current = setInterval(() => {
      fadeOut();
    }, 50);
  };

  return <></>;
};
export default MovingTextComponent;
