import React, { useState, useContext, useEffect, useRef } from "react";
import MariaModel from "./mariaModel.component";
import { Section3Context } from "../../context/section3.context";
import { useFrame, useLoader, useThree } from "@react-three/fiber";
import { useScroll } from "../ScrollControls";
import Section2BackgroundImages from "./backgroundImages";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { setScrollTopToPercentage } from "../../utils/setScrollPercentage";
import { ScrollContext } from "../../context/scroll.context";

const Section2 = () => {
  const {
    timeRange,
    setShowExperianceButton,
    showExperianceButton,
    setShowPlayButtons,
    showPlayButtons,
  } = useContext(Section3Context);

  const { scroll, el } = useScroll();
  const { width } = useThree((state) => state.viewport);
  const [mariaModelScale, setMariaModelScale] = useState();
  const { setEnableScroll, enableScroll } = useContext(ScrollContext);
  const hasDisabledScroll = useRef(false);
  const isSection3 = useRef(true);

  const gltfMOdel = useLoader(
    GLTFLoader,
    "/assets/3d-Models/Single_Maria_APPROVED.gltf"
  );
  const gltfMOdel2 = useRef();

  useEffect(() => {
    if (width < 5.67) {
      setMariaModelScale([width * 0.15, 0.8]);
    } else if (width < 7.7 && width >= 5.67) {
      setMariaModelScale([width * 0.1, 1]);
    } else if (width >= 7.7 && width < 11.34) {
      setMariaModelScale([width * 0.039, 1.15]);
    } else if (width >= 11.34 && width <= 14.5) {
      setMariaModelScale([width * 0.039, 1.15]);
    } else {
      setMariaModelScale([width * 0.039, 1.15]);
    }
  }, [width]);
  useEffect(() => {
    if (gltfMOdel && gltfMOdel.scene && !gltfMOdel2.current) {
      gltfMOdel2.current = gltfMOdel.scene.clone(true);
    }
  }, [gltfMOdel]);

  useEffect(() => {
    if (isSection3.current && !enableScroll) {
      setTimeout(() => {
        setEnableScroll(true);
      }, 2000);
    }
  }, [enableScroll, isSection3, width]);

  useFrame(() => {
    const currentScroll = scroll.current * 100;
    if (
      currentScroll > 25 &&
      currentScroll <= 50 &&
      !showExperianceButton &&
      !showPlayButtons
    ) {
      setShowExperianceButton(true);
    }
    if ((currentScroll < 25 || currentScroll > 50) && showExperianceButton) {
      setShowExperianceButton(false);
      setShowPlayButtons(false);
    }

    // section 2 exit
    if (currentScroll >= 47 && currentScroll < 53) {
      scroll.current -= 0.18 / 100;
    }
    if (currentScroll > 46.6 && currentScroll < 47) {
      setScrollTopToPercentage(47, el);
    }

    // section 3 entry
    if (currentScroll >= 53 && currentScroll < 61) {
      isSection3.current = true
      scroll.current += 0.18 / 100;
      if (currentScroll > 60.5 && currentScroll < 61) {
        setScrollTopToPercentage(61, el);
      }
    }

    // Disable scroll only once
    if (currentScroll > 60 && currentScroll < 70 && isSection3.current && enableScroll && !hasDisabledScroll.current) {
      setEnableScroll(false);
      //  setScrollTopToPercentage(61, el);
      scroll.current = 60 / 100
      hasDisabledScroll.current = true; // Prevents multiple executions
    }

    if ((currentScroll < 55 || currentScroll > 75) && hasDisabledScroll.current ) {
      hasDisabledScroll.current = false
      isSection3.current = false
    }
  });

  return (
    <>
      <Section2BackgroundImages
        width={9}
        height={width>5.67 ? 11.6 : 11.9}
        position={width>5.67 ? [0, -4.86, 2.4] : [0, -4.55, 2.4]}
        startTime={timeRange.startTime}
      />

      {width < 5.67 && <Section2BackgroundImages
        width={9}
        height={11.9}
        position={[0, -19.7, 2.4]}
        startTime={timeRange.startTime}
      />}


      {mariaModelScale && gltfMOdel && (
        <mesh position={[0, -9.7, 3]} rotation={[0, -1.8, 0]}>
          <MariaModel
            gltf={gltfMOdel.scene}
            scale={[mariaModelScale[0], 1.1, mariaModelScale[1]]}
            animate={false}
            showLight={true}
          />
        </mesh>
      )}
    </>
  );
};

export default Section2;
