import { useFrame, useThree } from "@react-three/fiber";
import PngLoader from "../pngLoader.component";
import Carousel from "./imageCarousel.component";
import { useScroll } from "../ScrollControls";
import { setScrollTopToPercentage } from "../../utils/setScrollPercentage";
import { useContext, useEffect, useRef, useState } from "react";
import { Section6Context } from "../../context/section6.context";
import { ScrollContext } from "../../context/scroll.context";

const Section3 = () => {
  const baseUrl = process.env.REACT_APP_CATALOGUE_URL;
  const { width } = useThree((state) => state.viewport);
  const { setEnableScroll, enableScroll } = useContext(ScrollContext);
  const hasDisabledScroll = useRef(false);
  const isSection4 = useRef(true);
  const [carouselScale, setCarouselScale] = useState();
  const [arrowPosition, setArrowPosition] = useState([1.3, 0]);
  const [positionsArr, setPositionsArr] = useState([
    [
      [-0.6, 0, 0],
      [0, 0, 0.1],
      [0.6, 0, 0],
    ],
  ]);

  const [scalesArr, setScalesArr] = useState([
    [0.8, 0.8, 1],
    [1, 1, 1],
    [0.8, 0.8, 1],
  ]);

  const { setShowBlueLogo, setAutoRotateCarousel } =
    useContext(Section6Context);

  useEffect(() => {
    if (width < 5.67) {
      setCarouselScale([0.47, 0.47, 1]);
      setArrowPosition([width * 0.02, -0.6]);
      setPositionsArr([
        [-0.5, 0, 0],
        [0, 0, 0.1],
        [0.5, 0, 0],
      ]);
      setScalesArr([
        [1, 1, 1],
        [1.3, 1.3, 1],
        [1, 1, 1],
      ]);
    } else if (width < 7.7 && width >= 5.67) {
      setCarouselScale([0.6, 0.6, 1]);
      setArrowPosition([width * 0.15, 0]);
      setPositionsArr([
        [-0.6, 0, 0],
        [0, 0, 0.1],
        [0.6, 0, 0],
      ]);
      setScalesArr([
        [0.8, 0.8, 1],
        [1, 1, 1],
        [0.8, 0.8, 1],
      ]);
    } else if (width >= 7.7 && width < 11.34) {
      setCarouselScale([0.7, 0.7, 1]);
      setArrowPosition([width * 0.12, 0]);
      setPositionsArr([
        [-0.6, 0, 0],
        [0, 0, 0.1],
        [0.6, 0, 0],
      ]);
      setScalesArr([
        [0.8, 0.8, 1],
        [1, 1, 1],
        [0.8, 0.8, 1],
      ]);
    } else if (width >= 11.34 && width <= 14.5) {
      setCarouselScale([0.9, 0.9, 1]);
      setArrowPosition([width * 0.09, 0]);
      setPositionsArr([
        [-0.6, 0, 0],
        [0, 0, 0.1],
        [0.6, 0, 0],
      ]);
      setScalesArr([
        [0.8, 0.8, 1],
        [1, 1, 1],
        [0.8, 0.8, 1],
      ]);
    } else {
      setCarouselScale([1, 1, 1]);
      setArrowPosition([1.3, 0]);
      setPositionsArr([
        [-0.6, 0, 0],
        [0, 0, 0.1],
        [0.6, 0, 0],
      ]);
      setScalesArr([
        [0.8, 0.8, 1],
        [1, 1, 1],
        [0.8, 0.8, 1],
      ]);
    }
  }, [width]);

  const { scroll, el } = useScroll();
  const images = [
    "assets/img/Bubba Kush.png",
    "assets/img/Amnesia Lemon.png",
    "assets/img/Dry sift.png",
    "assets/img/Durban Poison.png",
    "assets/img/Zkittles.png",
    "assets/img/Primero charas.png",
    "assets/img/Strawberry Gelato.png",
    "assets/img/Gorilla Mandarin.png",
    "assets/img/La creme.png",
    "assets/img/Lemonade CBG.png",
    "assets/img/Explore Catalogue Button.png",
  ];

  const imageUrls = [
    `${baseUrl}/flowers/bubba_kush/`,
    `${baseUrl}/flowers/amnesia_lemon/`,
    `${baseUrl}/hash/dry_sift/`,
    `${baseUrl}/flowers/durban_poison_gl/`,
    `${baseUrl}/flowers/zkittles/`,
    `${baseUrl}/hash/primero_charas/`,
    `${baseUrl}/flowers/strawberry_gelato/`,
    `${baseUrl}/flowers/gorilla_mandarin/`,
    `${baseUrl}/hash/la_creme/`,
    `${baseUrl}/flowers/lemonade_cbg/`,
    `${baseUrl}/`,
  ];

  useEffect(() => {
    if (isSection4.current && !enableScroll) {
      setTimeout(() => {
        setEnableScroll(true);
      }, 2000);
   }
  }, [enableScroll,isSection4]);

  useFrame(() => {
    const currentScroll = scroll.current * 100;

    // section 3 exit
    if (currentScroll >= 61.5 && currentScroll < 65) {
      scroll.current -= 0.18 / 100;
    }
    if (currentScroll > 61.2 && currentScroll < 61.5) {
      setScrollTopToPercentage(61.5, el);
    }

    // section 4 entry
    if (currentScroll >= 65 && currentScroll < 72) {
      isSection4.current = true
      scroll.current += 0.18 / 100;
      if (currentScroll > 71.5 && currentScroll < 72) {
        setScrollTopToPercentage(72, el);
      }
    }

    if (currentScroll > 58 && currentScroll <= 94) {
      setShowBlueLogo(true);
    } else {
      setShowBlueLogo(false);
    }

    if (currentScroll > 61 && currentScroll < 65) {
      setAutoRotateCarousel(true);
    } else {
      setAutoRotateCarousel(false);
    }

      // Disable scroll only once
      if (currentScroll > 72 && currentScroll<80 && isSection4.current  && enableScroll && !hasDisabledScroll.current) {
        setEnableScroll(false);
        // setScrollTopToPercentage(72, el);
        scroll.current = 72/100
       hasDisabledScroll.current = true; // Prevents multiple executions
     }
 
     if ((currentScroll <72 || currentScroll > 80) && hasDisabledScroll.current) {
       hasDisabledScroll.current = false
       isSection4.current = false
     }
  });

  return (
    <>
      <PngLoader
        transparent={true}
        renderOrder={-10}
        path="assets/img/Last-Section-Image-05.png"
        position={[0, -3.22, 1.9]}
        zoom={0.7}
        scale={[2, 2.7, 1]}
      />
      <Carousel
        images={images}
        imageUrls={imageUrls}
        iconTexturePath={"assets/img/Explore 3D Catalogue button - 4X.png"}
        scale={carouselScale}
        arrowPosition={arrowPosition}
        positionsArr={positionsArr}
        scalesArr={scalesArr}
      />
      ;
    </>
  );
};

export default Section3;
